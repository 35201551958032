"use strict";

(function () {
  window.universeProprietaryPages = {
    has: function has(channel) {
      return Object.prototype.hasOwnProperty.call(this.pages, channel) && this.pages[channel].length > 0;
    },
    hasValidPage: function hasValidPage(channel) {
      var pages = this.getAll(channel);
      return pages && pages.some(function (page) {
        return page.validToken && page.isActive;
      });
    },
    hasPageFromBasicChannel: function hasPageFromBasicChannel() {
      var _this = this;
      return this.basicChannel.reduce(function (hasPage, channel) {
        return _this.has(channel) || hasPage;
      }, false);
    },
    hasSomePage: function hasSomePage() {
      var thereIsPage = false;
      for (var channel in this.pages) {
        if (this.pages[channel].length > 0) {
          thereIsPage = true;
        }
      }
      return thereIsPage;
    },
    getAll: function getAll(channel) {
      return this.pages[channel] ? this.pages[channel] : false;
    },
    insert: function insert(channel, page) {
      try {
        return this.pages[channel].push(page);
      } catch (err) {
        console.error('Tried to insert invalid channel.', err);
        return false;
      }
    },
    basicChannel: ['facebook', 'twitter', 'instagram', 'youtube'],
    pages: {
      twitter: [],
      facebook: [],
      instagram: [],
      youtube: [],
      linkedin: [],
      googlemybusiness: [],
      googleplay: [],
      TikTok: []
    }
  };
})();